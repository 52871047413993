import React from "react";
import { useTranslation } from "react-i18next";
import FeatureContainerThree from "../featureContainerThree";

export default () => {
  const { t } = useTranslation();
  return (
    <div className="three-features-grid">
      <FeatureContainerThree
        excerpt={"homepage_features_31"}
        headerIconSource={require(`../../../assets/watch_icon.png`)}
      />
      <FeatureContainerThree
        excerpt={"homepage_features_32"}
        headerIconSource={require(`../../../assets/icon5.png`)}
      />
      <FeatureContainerThree
        excerpt={"homepage_features_33"}
        headerIconSource={require(`../../../assets/icon6.png`)}
      />
    </div>
  );
};
