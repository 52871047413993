import React from "react";
import { useTranslation } from "react-i18next";
import FeatureContainerFour from "../featureContainerFour";

export default () => {
  const { t } = useTranslation();
  return (
    <div className="four-features-grid">
      <FeatureContainerFour
        excerpt={"homepage_features_41"}
        headerIconSource={require(`../../../assets/icon1.png`)}
      />
      <FeatureContainerFour
        excerpt={"homepage_features_42"}
        headerIconSource={require(`../../../assets/icon2.png`)}
      />
      <FeatureContainerFour
        excerpt={"homepage_features_43"}
        headerIconSource={require(`../../../assets/icon3.png`)}
      />
      <FeatureContainerFour
        excerpt={"homepage_features_44"}
        headerIconSource={require(`../../../assets/icon4.png`)}
      />
    </div>
  );
};
