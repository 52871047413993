import React from "react";
import { isIOS, isIOS13, isIPad13, isMobile } from "react-device-detect";
import Homepage1 from "../components/homepage/homepage-1";
import Homepage2 from "../components/homepage/homepage-2";
import Layout from "../components/layout";
import "../components/layout.css";

interface HomepageStates {
  backgroundAttachmentString?: string;
}

class Homepage extends React.Component<{}, HomepageStates> {
  constructor(props: undefined) {
    super(props);
    this.state = {
      backgroundAttachmentString: `inherit`
    };
  }

  public componentDidMount() {
    this.setState({
      backgroundAttachmentString:
        isMobile || isIOS || isIPad13 || isIOS13 ? "inherit" : "fixed"
    });
  }

  public render() {
    return (
      <Layout>
        <Homepage1 />
        <Homepage2
          backgroundAttachmentString={this.state.backgroundAttachmentString}
        />
      </Layout>
    );
  }
}

export default Homepage;
