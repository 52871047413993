import { navigate } from "gatsby";
import React from "react";
import { Trans } from "react-i18next";
import logo from "../../../assets/surya-digital-logo.png";
import Container from "../container";
import "../layout.css";
import HomepageFourFeatures from "./homepageFourFeatures";
import HomepageImageContainerWithHeaderOnly from "./homepageImageContainer";
import HomepageThreeFeatures from "./homepageThreeFeatures";

class Homepage1 extends React.Component {
  public render() {
    return (
      <div className="homepage1">
        <HomepageImageContainerWithHeaderOnly
          className="background-homepage1"
          excerpt="homepage1_excerpt_white"
          highlight="homepage1__excerpt_highlight"
          excerptEnd="."
          scrollId="#homepage1"
        />
        <Container>
          <div id="homepage1">
            <img
              src={logo}
              alt="Header Logo"
              style={{ width: `275px`, marginTop: `15px` }}
            />
            <h2>
              <Trans i18nKey={"homepage1_header"} />
            </h2>
          </div>
          <div className="feature-container-div">
            <div>
              <HomepageFourFeatures />
            </div>
            <div className="homepage1-button1">
              <div className="button-div">
                <a
                  href="/services/"
                  className="button"
                  style={{
                    color: `white`,
                    marginLeft: `auto`,
                    outline: `none`,
                    textDecoration: `inherit`
                  }}
                  onClick={() => {
                    if (typeof localStorage !== "undefined") {
                      localStorage.setItem("scrollPosition", (0).toString());
                    }
                  }}
                >
                  <Trans i18nKey="homepage1_service_button_name" />
                  &nbsp;&nbsp;
                  <Trans i18nKey="homepage1_button_arrow" />
                </a>
              </div>
            </div>
          </div>
          <p className="homepage1-header-mid">
            <Trans i18nKey="homepage1_header_mid_white" />
            <span className="colored-p">
              <Trans i18nKey="homepage1_header_mid_highlight" />{" "}
            </span>
            <Trans i18nKey="homepage1_header_mid_end" />
          </p>
          <h2 className="homepage1-concentrate">
            <Trans i18nKey="homepage1_header_mid2_white" />
            <span style={{ display: `block`, marginTop: `-10px` }}>
              <Trans i18nKey="homepage1_header_mid2_white2" />{" "}
              {
                <p className="colored-p">
                  <Trans i18nKey="homepage1_header_mid2_highlight" />
                </p>
              }
            </span>
          </h2>
          <div className="feature-container-div">
            <div>
              <HomepageThreeFeatures />
            </div>
            <div className="homepage1-button2" style={{ display: `none` }}>
              <button
                className="button"
                onClick={() => {
                  navigate("/references/");
                }}
              >
                <Trans i18nKey="homepage1_reference_button_name" />
              </button>
            </div>
          </div>
          <p className="homepage1-header-mid" style={{ marginBottom: `0` }}>
            <Trans i18nKey="homepage1_bottom_text" />{" "}
            {
              <span className="colored-p" style={{ display: `block` }}>
                <Trans i18nKey="homepage1_bottom_text_highlight" />
              </span>
            }
          </p>
        </Container>
      </div>
    );
  }
}

export default Homepage1;
