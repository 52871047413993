import React from "react";
import { Trans } from "react-i18next";
import tilt_border_footer from "../../../assets/tilt_border_footer.png";
import "../layout.css";

interface Homepage2ContainerProps {
  backgroundAttachmentString?: string;
  height?: number;
}

interface Homepage2ContainerState {
  animationEnd: boolean;
  height?: number;
  oldScroll: number;
  scrollDir: boolean;
  transition: string;
}

const hasWindow = typeof window !== "undefined" ? true : false;

class Hompegage2 extends React.Component<
  Homepage2ContainerProps,
  Homepage2ContainerState
> {
  constructor(props: Homepage2ContainerProps) {
    super(props);
    this.state = {
      animationEnd: false,
      height: 550,
      oldScroll: 0,
      scrollDir: true,
      transition: `width 8s, height 0s`
    };
  }

  public componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("animationstart", this.handleAnimationStart);
    window.addEventListener("animationend", this.handleAnimationEnd);
  }

  public componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
    window.removeEventListener("resize", this.updateDimensions);
    window.removeEventListener("animationstart", this.handleAnimationStart);
    window.removeEventListener("animationend", this.handleAnimationEnd);
  }

  public render() {
    return (
      <div style={{ overflow: `hidden` }}>
        <div
          style={{
            marginBottom: `-7.5%`,
            position: `absolute`,
            width: `100%`,
            zIndex: 5
          }}
        >
          <img
            className="container-footer"
            alt="Top Border"
            src={tilt_border_footer}
          />
        </div>
        <div
          className="background-homepage2"
          style={
            this.state.scrollDir
              ? {
                  animationName: `grow`,
                  backgroundAttachment: this.props.backgroundAttachmentString,
                  height: this.state.height,
                  transition: this.state.transition
                }
              : {
                  animationName: `shrink`,
                  backgroundAttachment: this.props.backgroundAttachmentString,
                  height: this.state.height,
                  transition: this.state.transition
                }
          }
        >
          <div
            className="homepage2"
            style={
              this.state.scrollDir
                ? { animationName: `excerpt-shrink` }
                : { animationName: `excerpt-grow` }
            }
          >
            <h3 className="homepage2-p-header">
              <Trans i18nKey="homepage2_text_header" />
              {"\n"}
              <span className="colored-p">
                <Trans i18nKey="homepage2_text_header_highlight" />
              </span>
              <Trans i18nKey="." />
            </h3>
            <p className="homepage2-p">
              {
                <span className="colored-p-lower">
                  <Trans i18nKey="homepage2_text_para_highlight" />
                </span>
              }
              <Trans i18nKey="homepage2_text_para_white" />
            </p>
          </div>
        </div>
      </div>
    );
  }

  private handleAnimationEnd = () => {
    this.setState({ animationEnd: true });
  };

  private handleAnimationStart = () => {
    this.setState({ animationEnd: false });
  };

  private updateDimensions = () => {
    this.state.height === 550
      ? this.setState({ transition: `width 8s, height 0s` })
      : this.setState({ transition: `width 8s, height 8s` });

    if (
      parseFloat(localStorage.getItem("background2Height")) !==
      window.innerWidth * 0.588
    ) {
      localStorage.setItem(
        "background2Height",
        (window.innerWidth * 0.588).toString()
      );
    }
    this.setState({
      height: parseFloat(localStorage.getItem("background2Height"))
    });
  };

  private handleScroll = () => {
    const newScroll = window.pageYOffset;
    if (this.state.oldScroll - newScroll < -100) {
      this.setState({ scrollDir: true });
    } else if (this.state.oldScroll - newScroll > 100) {
      this.setState({ scrollDir: false });
    }
    this.setState({ oldScroll: newScroll });
  };
}

export default Hompegage2;
