import { Link } from "gatsby";
import React from "react";
import { isIOS, isIOS13, isIPad13, isMobile } from "react-device-detect";
import { NavLink } from "reactstrap";
import arrow_button_1 from "../../../assets/arrow-button-1.png";
import tilt_border from "../../../assets/tilt_border.png";
import Excerpt from "../excerpt";

interface HomepageImageContainerProps {
  className?: string;
  excerpt?: string;
  highlight?: string;
  excerptEnd?: string;
  scrollId?: string;
}

interface HomepageImageContainerStates {
  height?: number;
  backgroundAttachmentString?: string;
}

const hasWindow = typeof window !== "undefined" ? true : false;

class HomepageImageContainerWithHeaderOnly extends React.Component<
  HomepageImageContainerProps,
  HomepageImageContainerStates
> {
  constructor(props: HomepageImageContainerProps) {
    super(props);
    this.state = {
      backgroundAttachmentString: `inherit`,
      height: hasWindow ? window.innerHeight : 950
    };
  }

  public render() {
    return (
      <div style={{ overflow: `hidden` }}>
        <div
          className={this.props.className}
          style={{
            backgroundAttachment: this.state.backgroundAttachmentString,
            height: this.state.height + 0.015 * this.state.height
          }}
        >
          <Excerpt
            excerpt={this.props.excerpt}
            excerptEnd={this.props.excerptEnd}
            highlight={this.props.highlight}
            style={{
              animation: `none`,
              bottom: `unset`
            }}
          />
          <div className="border-margin">
            <NavLink
              tag={Link}
              id="scrollUpper"
              to={"#scrollUpper"}
              className={this.props.scrollId ? `scrollid` : `scrollid-hide`}
            >
              {" "}
              <img src={arrow_button_1} />
            </NavLink>
            <img
              className="container-header"
              alt="Bottom Border"
              style={{ animation: `neutral 5s forwards`, marginTop: `1.4%` }}
              src={tilt_border}
            />
          </div>
        </div>
      </div>
    );
  }

  public componentDidMount() {
    this.updateDimensions();
    window.addEventListener("resize", this.updateDimensions);
    this.setState({
      backgroundAttachmentString:
        isMobile || isIOS || isIPad13 || isIOS13 ? "scroll" : "fixed"
    });
  }

  public componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  private updateDimensions = () => {
    this.setState({
      height: window.innerWidth * 0.59
    });
  };
}

export default HomepageImageContainerWithHeaderOnly;
